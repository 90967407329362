$('.center').slick({
  centerMode: true,
  centerPadding: '24px',
  slidesToShow: 6,
  responsive: [
    {
      breakpoint: 768,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '8px',
        slidesToShow: 4
      }
    },
    {
      breakpoint: 480,
      settings: {
        arrows: false,
        centerMode: true,
        centerPadding: '8px',
        slidesToShow: 2,
        variableWidth: true,
        autoplay: true,
      }
    }
  ]
});
$('.js-top-service-caroucel').slick({
  infinite: true,
  speed: 1000,
  arrows: false,
  fade: true,
  cssEase: 'linear',
  autoplay: true,
  pauseOnFocus: false
});
function slideHuck(){
  var w = $(window).width();
  var size = 640;
  if (w >= size) {
    $('.js-footer-banner').slick({
      centerMode: true,
      variableWidth: true,
      infinite: true,
      slidesToShow: 6,
    });
  } else {
    $(".js-footer-banner").unslick(); /*slickを解除*/
  }
}
slideHuck();
$('.js-service-slide').slick({
  infinite: true,
  speed: 500,
  fade: true,
  cssEase: 'linear'
});
